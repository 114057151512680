import * as React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'; 
import axios, { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import { authCheck } from '../../../utils/authCheck'
import ScoreTypeSelect from './ScoreType';
import ScoreUpdateSelect from './ScoreUpdate';
import { Box, Button, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { apiRequest } from '../../../utils/apiRequest';

export interface IStanding {
  id: number
  userName: string
    name: string
    selectedTeam: string
    odds: string
    overUnder: string
    vsTeam: string
    pickNum: string
    GameStartTime: string
    GameResult: string
    result: number
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  export default function DashboardContent(args: any) { 
  const [picks, setPicks] = React.useState([] as any[]);
  const [user, setUser] = React.useState(args.user as any) 
  const [selected, setSelected] = React.useState(0);
  const [width, setWidth] = React.useState<number>(window.innerWidth);
 
  const [results, setResults] = React.useState({} as any)


    useEffect(()=>{  
      if(authCheck.isAdmin(user.userName)){
        const picks = async() => await getScores();
        picks();

        setUser(args.user)

       
        
      }
        
    }, [args]) // <-- empty dependency array
 

  const getScores = async (scoreType = 0) => {  
    const result = await apiRequest.sendRequest(`api/v1/admin/scores`, 'post', { scoreType });
    setPicks(result)

    result.map((pick: any) => {
      results[pick.id] = pick.result
    })
    setResults(results)
  }
 
  const onSelect = async (value: number) => { 
    await getScores(value); 
    setSelected(value)
  }
 
  const expectedResult = (result: number) => {
    switch(result){
      case 0: 
        return 'Pending'
      case 1: 
        return 'Win';
      case 2: 
        return 'Loss'
        case 3: 
        return 'Push'
        case 4: 
        return 'Loss'
        case 5: 
        return 'Double Push'
        
    }
  }
  const onUpdate = async ( ) => { 
    const updates = picks.filter((pick) => pick.GameResult !== expectedResult(pick.result))
 
    await apiRequest.sendRequest(`api/v1/admin/scores/update`, 'post', { cookie: user.auth,  updates })  
    await getScores(selected); 
  }

  return (
    
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
            
            <ScoreTypeSelect onSelect={{ onSelect }} /><Button variant="outlined" onClick={onUpdate}>Update</Button>
     
              <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell> 
            <TableCell>Game time</TableCell>
            <TableCell>Team</TableCell>
            <TableCell>Odds</TableCell>
            <TableCell>O/U</TableCell>
            <TableCell>Game</TableCell>
            <TableCell>result</TableCell>
            <TableCell>actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {picks.map((row) => (
            <StyledTableRow
              key={row.id              }
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={{ background: row.pickType === 1 ? 'yellow' : ''}}
            >
              <TableCell component="th" scope="row">
                {row.userName}
              </TableCell>
              <TableCell scope="row">
                {row.GameStartTime}
              </TableCell>
              <TableCell scope="row">
                {row.selectedTeam}
              </TableCell>
              <TableCell scope="row">
                {row.odds}
              </TableCell>
              <TableCell scope="row">
                {row.overUnder}
              </TableCell>
              <TableCell scope="row">
              {(row.finalScore && row.finalScore?.length) ? row.finalScore : row.vsTeam} 
              </TableCell>
              <TableCell scope="row">
                {row.GameResult}
              </TableCell>
              <TableCell scope="row">
            
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth> 
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={results[row.id] ? results[row.id].toString() : ''} 
                      onChange={(e: SelectChangeEvent) => {
                        row.result = parseInt(e.target.value) 
                        const tmp = JSON.parse(JSON.stringify(results))
                        tmp[row.id] = row.result;
                        setResults(tmp) 
                      }}
                    >
                      <MenuItem value={'0'}>Pending</MenuItem>
                      <MenuItem value={'1'}>Win</MenuItem>
                      <MenuItem value={'2'}>Loss</MenuItem>
                      <MenuItem value={'3'}>Push</MenuItem>
                      <MenuItem value={'4'}>Void</MenuItem>
                      <MenuItem value={'5'}>Double Push</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
 
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </Grid> 
          </Container>
  );
}
