import axios        from 'axios'; 

export default {
  setupInterceptors: () => {

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) { 
        if(error.response.status === 403 || error.response.status === 401){ 
            console.log("EXPIRED TOKEN!");
            localStorage.clear();  
            window.location.href = '/login'
            return Promise.reject(error);
        } 
    }); 
  }
};