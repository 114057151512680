import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function WeekSelect(args: any) {
  const [age, setAge] = React.useState('');
    const [func] = React.useState(args);
  const handleChange = async (event: SelectChangeEvent) => {
    const value = event.target.value as string
    setAge(value);
    await func.onSelect(value)
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Filter by</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Score type"
          onChange={handleChange}
        >
          <MenuItem value={'18'}>18</MenuItem>
          <MenuItem value={'17'}>17</MenuItem>
          <MenuItem value={'16'}>16</MenuItem>
          <MenuItem value={'03'}>03</MenuItem>
          <MenuItem value={'02'}>02</MenuItem>
          <MenuItem value={'01'}>01</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}