import * as React from 'react'; 
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';    
import { Button, TextField } from '@mui/material';
import SportTypeSelect from './SportType';
 
  export default function Leagues(args: any) {
  
  const [user, setUser] = React.useState((args.user ? args.user : { }) as any) 
  const [groupName, setGroupName] = React.useState('')
  const [maxPicks, setMaxPicks] = React.useState('60')
  const [maxMembers, setMaxMembers] = React.useState('60')
  const [minBuyin, setMinBuyIn] = React.useState('500')
  const [maxBuyin, setMaxBuyIn] = React.useState('1000')
    const [regCode, setRegCode] = React.useState('')
    const [startDate, setStartDate] = React.useState('')
    const [sportTypeId, setSportTypeId] = React.useState('1')

    const addLeague = () => {
      const league = {
        groupName, maxMembers, maxPicks, sportTypeId, minBuyin, maxBuyin, startDate
      }
      alert(JSON.stringify(league))
    }

  return (
   
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
             
              {/* Recent Deposits */}
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column', 
                  }}
                >   
Create a new league
<br/>
<div>&nbsp;</div>
Fill the form data to setup a new league
<div>&nbsp;</div>
<TextField
        id="outlined-uncontrolled"
        label="League name"
        value={groupName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setGroupName(event.target.value);
          }}
      /><div>&nbsp;</div>
       <TextField
              id="outlined-uncontrolled"
              label="max members"
              value={maxMembers}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setMaxMembers(event.target.value);
                }}
            /><div>&nbsp;</div>
            
<TextField
        id="outlined-uncontrolled"
        label="max picks"
        value={maxPicks}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setMaxPicks(event.target.value);
          }}
      /><div>&nbsp;</div> 
      <TextField
              id="outlined-uncontrolled"
              label="min buy in"
              value={minBuyin}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setMinBuyIn(event.target.value);
                }}
            /><div>&nbsp;</div>
            
<TextField
        id="outlined-uncontrolled"
        label="max buy in"
        value={maxBuyin}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setMaxBuyIn(event.target.value);
          }}
      /><div>&nbsp;</div>
      <SportTypeSelect args={{setSportTypeId}}/>    
      <div>&nbsp;</div>
<TextField
        id="outlined-uncontrolled"
        label="Registration code"
        value={regCode}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setRegCode(event.target.value);
          }}
      /><div>&nbsp;</div> 
      Pool start date<div>&nbsp;</div> 
<TextField
        id="outlined-uncontrolled" 
        value={startDate}
        type="datetime-local"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setStartDate(event.target.value);
          }}
      /><div>&nbsp;</div>
      <Button onClick={addLeague} variant='outlined'>Create</Button>
                </Paper>
              </Grid>  
          </Container>
  );
}
