import * as React from 'react'; 
import { IGetDashboard } from '../interfaces';
import { useEffect } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, CardContent, Typography } from '@mui/material';

interface IStatsArgs {
  info: IGetDashboard
} 

export default function FixedContainer(args: IStatsArgs) {
  const [data, setData] = React.useState([] as any);
  useEffect(()=>{
    let rows = [
 
    ] as any[]; 
    rows.push(createData('Number of entrants', args.info.totalUsers)) 
    rows.push(createData('Maximum number of picks per entrant', args.info.maxPicks))
    setData(rows)
}, [args]) // <-- empty dependency array

  const createData = (name: string,value: number) => {
    return { name, value };
  }

  return (<React.Fragment>
    <CardContent>
    <Typography sx={{ 'padding-left': 10, fontSize: 18, color: '#333', 'background-color': '#f5f5f5', 'border-color': '#ddd' }} color="text.secondary" gutterBottom>
    Pool Statistics

    </Typography>
    <TableContainer component={Paper}>
      <Table sx={{  }} aria-label="simple table"> 
        <TableBody>
          {data.map((row: any) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.value}</TableCell> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
 
    </CardContent> </React.Fragment>
  );
}