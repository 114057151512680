

import * as React from 'react'; 
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';   
import { apiRequest } from '../../../utils/apiRequest';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, styled } from '@mui/material';
 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  export default function NflScores(args: any) {
  
  const [user, setUser] = React.useState((args.user ? args.user : { }) as any)  
  const [scores, setScores] = React.useState([] as any[]);
  const [year, setYear] = React.useState('2023');
  const [week, setWeek] = React.useState('01');
    const onSelect = () => {

    }

  return (
   
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
             
              {/* Recent Deposits */}
              <Grid item xs={12} md={6} lg={6}>
             

<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
  <TableHead>
    <TableRow>
      <TableCell>gameID</TableCell>
      <TableCell>Game time</TableCell>
      <TableCell>gameState</TableCell>
      <TableCell>title</TableCell> 
    </TableRow>
  </TableHead>
  <TableBody>
    {scores.map((row: any) => (
      <StyledTableRow
        key={row.game.gameID}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }} 
      >
        <TableCell component="th" scope="row">
          {row.game.gameID} 
        </TableCell>
        <TableCell scope="row">
         {row.startDate} {row.game.startTime}
        </TableCell>
        <TableCell scope="row">
          {row.game.gameState}
        </TableCell> 
        <TableCell scope="row">
          {row.game.title}
        </TableCell> 
        
      </StyledTableRow>
    ))}
  </TableBody>
</Table>
</TableContainer>
                 
                </Grid>
                
            </Container>
    );
  }
  