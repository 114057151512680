import * as React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'; 
import axios, { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import { authCheck } from '../../../utils/authCheck' 
import { Button } from '@mui/material';
import { apiRequest } from '../../../utils/apiRequest';
import EditUser from './Edit';

export interface IStanding {
  id: number
  userName: string
    name: string
    selectedTeam: string
    odds: string
    overUnder: string
    vsTeam: string
    pickNum: string
    GameStartTime: string
    GameResult: string
    result: number
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  export default function Users(args: any) { 
  const [users, setUsers] = React.useState([] as any[]);
  const [user, setUser] = React.useState(args.user as any) 
  const [props] = React.useState(args);
  const [width, setWidth] = React.useState<number>(window.innerWidth);
 
  const isMobile = width <= 515;
 
    const loginUser = async (userName: string) => {
      const result = await apiRequest.sendRequest('api/v1/admin/users/login', 'post', { userName }) 
      if(result){
        const currentUser =  JSON.parse(localStorage.getItem('user') as string)

        const { user, token } = result;
        user.leagues = currentUser.leagues; 
    
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('token', token)
        localStorage.setItem('cookie', user.auth)
        localStorage.setItem('leagues', JSON.stringify(user.leagues))

        window.location.href= '/dashboard'
      }
    }

    useEffect(()=>{  
      if(authCheck.isAdmin(user.userName)){
        const picks = async() => await getUsers();
        picks();

        setUser(args.user)
      }
        
    }, [args]) // <-- empty dependency array
 

  const getUsers = async (scoreType = 0) => { 
    const result = await apiRequest.sendRequest('api/v1/admin/users', 'post', { scoreType }); 
    setUsers(result) 
  } 

  const updateUser = async (user: any) => {
    await apiRequest.sendRequest('api/v1/admin/user/update', 'post', { user }); 
    await getUsers();
    return true;
  }

  return (
    
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
             
              <TableContainer component={Paper}>
      <Table sx={{ minWidth: (isMobile ? width -125 : 650) }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell> 
            <TableCell style={{ display: (isMobile ? 'none' : ' ')}}>Email</TableCell>
            <TableCell>GroupId</TableCell> 
            <TableCell style={{ display: (isMobile ? 'none' : ' ')}}>Name</TableCell>
            <TableCell>Last Login</TableCell> 
            <TableCell>actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row) => (
            <StyledTableRow
              key={row.id              }
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.userName}
              </TableCell>
              <TableCell scope="row" style={{ display: (isMobile ? 'none' : ' ')}}>
                { row.email }
              </TableCell>              <TableCell scope="row" style={{ display: (isMobile ? 'none' : ' ')}}>
                { row.groupId }
              </TableCell>
              <TableCell scope="row" style={{ display: (isMobile ? 'none' : ' ')}}>
              { row.firstName } { row.lastName }
              </TableCell>
              <TableCell scope="row" style={{ display: (isMobile ? 'none' : ' ')}}>
              { new Date(row.lastLogin).toDateString() } { new Date(row.lastLogin).toLocaleTimeString() }
              </TableCell> 
              <TableCell scope="row"> 
              <EditUser user={row} updateUser={updateUser} />  |  <a href='#' onClick={async () => loginUser(row.userName)}>login</a> |  <a href='#' onClick={() => alert(row.userName)}>delete</a>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </Grid> 
          </Container>
  );
}
