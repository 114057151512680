import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { configs } from '../../utils/configs'
import Progress from '../Dashbaord/Odds/Progress';
const baseUrl = configs.baseUrl.length ? configs.baseUrl : 'localhost'
const theme = createTheme();
export default function SignIn(args: any) {
  const [user, setUser] = React.useState(undefined as any)
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true)
    const login = {
      username: data.get('username'),
      password: data.get('password'),
    } 

    const headers = {};
    const url = `${baseUrl.indexOf('localhost') > -1 ? 'http' : 'https'}://${baseUrl}/api/v1/user/login`
    setError(``)
    try {
    const result = await axios.post(url, login, headers) 
   
    if(result){  
      args.auth(result.data, result.data.token)
      setUser(result.data) 
    } 
    else {
      setError(`Server error, try again`)
    }
  }
  catch(e){
    setError(`Bad login or password, try again`)
  }
  setLoading(false)
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
           { loading && 
                  <Progress />
                }

          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            PickSixty v3
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <div style={{ border: '1px solid red', padding: 20, display: (error.length ? 'block' : 'none') }} >
                  ERROR: { error }
                </div>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
             
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                
              </Grid>
              <Grid item>
                <Link href="#" variant="body2"> 
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box> 
        {user && (
          <Navigate to="/dashboard" replace={true} />
        )}
      </Container>
    </ThemeProvider>
  );
}