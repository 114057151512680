import * as React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';  
import { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import { apiRequest } from '../../../utils/apiRequest';

export interface IStanding {
    name: string
    selectedTeam: string
    odds: string
    overUnder: string
    vsTeam: string
    pickNum: string
    GameStartTime: string
    GameResult: string
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  export default function DashboardContent(args: any) { 
  const [picks, setPicks] = React.useState([] as any[]);
  const [user, setUser] = React.useState(args.user as any) 
  const [open, setOpen] = React.useState(true);
  const [width, setWidth] = React.useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  } 
  const isMobile = width <= 768;


    useEffect(()=>{ 
        const picks = async() => await getMyPicks();
        picks();
 

        setUser(args.user)
    }, [args]) // <-- empty dependency array
 

  const getMyPicks = async () => { 
    const result = await apiRequest.sendRequest('api/v1/user/picks', 'post') 
    setPicks(result)
    console.log(result)
  } 

  const getBackgroundColor = (result: number) => {
    switch(result){
      case 2: 
      return '#d9534f'
      case 1:
        return '#5cb85c'
        case 4:
          return "#999"
          case 3:
            return "#CCC"
            case 5:
              return "#999"
    }
  }

  return (
    
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
           
            { user.groupId === 0 && 
                <><div style={{ padding: 20 }}>No group associated, join a <a style={{paddingLeft: 5}} href='/leagues'>league</a></div></>
              }
              { user.groupId > 0 && 
              <> 
        <h2 style={{paddingLeft: 20 }}>My picks</h2>
              <TableContainer component={Paper}>
      <Table sx={{ minWidth: (isMobile ? width -125 : 650) }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ display: (isMobile ? 'none' : ' ')}}>Pick</TableCell>
            <TableCell style={{ display: (isMobile ? 'none' : ' ')}}>Game time</TableCell>
            <TableCell>Team</TableCell>
            <TableCell>Odds</TableCell>
            <TableCell>O/U</TableCell>
            <TableCell>Game</TableCell>
            <TableCell style={{ display: (isMobile ? 'none' : ' ')}}>Created</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {picks && picks?.map((row) => (
            <StyledTableRow
              key={row.pickNum}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={{ background: (row.pickType === 1 && row.GameResult === "Pending" ? '#FFFFBF' : getBackgroundColor(row.result)) }}
            >
              <TableCell component="th" scope="row" style={{ display: (isMobile ? 'none' : ' ')}}>
                {row.pickNum}
              </TableCell>
              <TableCell scope="row" style={{ display: (isMobile ? 'none' : ' ')}}>
              { new Date(row.gameTime).toLocaleDateString() } { new Date(row.gameTime).toLocaleTimeString() }
              </TableCell>
              <TableCell scope="row">
                {row.selectedTeam}
              </TableCell>
              <TableCell scope="row">
                {row.odds}
              </TableCell>
              <TableCell scope="row">
                {row.overUnder}
              </TableCell>
              <TableCell scope="row">
              {(row.finalScore && row.finalScore?.length) ? row.finalScore : row.vsTeam} 
              </TableCell>
              <TableCell scope="row" style={{ display: (isMobile ? 'none' : ' ')}}>
                { new Date(row.createDate).toLocaleDateString() } { new Date(row.createDate).toLocaleTimeString() }
              </TableCell> 
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  }
            </Grid> 
          </Container>
  );
}
