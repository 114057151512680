import * as React from 'react'; 
import { IGetDashboard } from '../interfaces';
import { useEffect } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, CardContent, Typography, Avatar } from '@mui/material';

interface IStatsArgs {
  info: IGetDashboard
} 

export default function FixedContainer(args: IStatsArgs) {
  const [data, setData] = React.useState({} as any);
  const [leaders, setLeaders] = React.useState([] as any);

  useEffect(()=>{
 
        setData(args.info)  
        let rows = [
 
        ] as any[]; 
        args.info?.numWinsLeader?.map((leader: any) => {
            const data = createData(<Avatar alt={ leader.Capper } src={ leader.Avatar } />, leader.Capper, `${leader.Wins} wins - ${leader.Losses} losses - ${leader.Pending} pending`)
            
            rows.push(data)   
        })
        setLeaders(rows)

}, [args]) // <-- empty dependency array

const createData = (avatar: Object, name: string, value: string) => {
  return { avatar, name, value };
}
 

  return (<React.Fragment>
    <CardContent>
    <Typography sx={{ 'padding-left': 10, fontSize: 18, color: '#333', 'background-color': '#f5f5f5', 'border-color': '#ddd' }} color="text.secondary" gutterBottom>
    Leader board

    </Typography>
    <TableContainer component={Paper}>
      <Table sx={{  }} aria-label="simple table"> 
        <TableBody>
          {leaders.map((row: any) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.avatar}
                {row.name}
              </TableCell>
              <TableCell align="right">{row.value}</TableCell> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
 
    </CardContent> </React.Fragment>
  );
}