import * as React from 'react';
import { Theme, makeStyles, styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'; 
import axios, { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { apiRequest } from '../../../utils/apiRequest';
 

export interface IStanding {
    name: string
    selectedTeam: string
    odds: string
    overUnder: string
    vsTeam: string
    pickNum: string
    GameStartTime: string
    GameResult: string
    userName?: string
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  export default function DashboardContent(args: any) { 
  const [picks, setPicks] = React.useState([] as any[]);
  const [user, setUser] = React.useState(args.user as any) 
  const [open, setOpen] = React.useState(true);
  const [width, setWidth] = React.useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  } 
  const isMobile = width <= 768;


    useEffect(()=>{ 
        const picks = async() => await getMyPicks();
        picks();
 

        setUser(args.user)
    }, [args]) // <-- empty dependency array
 

  const getMyPicks = async () => { 
    const result = await apiRequest.sendRequest('api/v1/dashboard/picks', 'post')  
    setPicks(result) 
  } 



  const getBackgroundColor = (result: string) => {
    switch(result){
      case "Loss": 
      return '#d9534f'
      case "Win":
        return '#5cb85c'
        case "Void":
          return "#999"
          case "Push":
            return "#CCC"
            case "DoublePush":
              return "#999"
    }
  }
   
  const columns: GridColDef[] = [ 
    { field: 'userName', headerName: 'userName',    }, 
    { field: 'GameStartTime', headerName: 'GameStartTime', minWidth: 150   },
    { field: 'selectedTeam', headerName: 'selectedTeam', minWidth: 150   }, 
    { field: 'odds', headerName: 'odds',    }, 
    { field: 'overUnder', headerName: 'total',    }, 
    { field: 'finalScore', headerName: 'finalScore', minWidth: 600   },  
    { field: 'GameResult', headerName: 'Result',    }, 
  ]
  const getEmbargoText = () => {
    const league = user.leagues.filter((league:any) => league.groupId === user.groupId);
    return (
      <>Picks hidden until official start of the tournament on {new Date(league[0].poolStart).toLocaleDateString()} {new Date(league[0].poolStart).toLocaleTimeString()}</>
    )
  }
  const checkEmbargo = () => {
    if(user.groupId > 0 && user.leagues.length){ 
      const league = user.leagues.filter((league:any) => league.groupId === user.groupId);
      return new Date(league[0].poolStart).getTime() > new Date().getTime();
    }
    return true
  }
  return (
    
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container  >
            <style>
           {
            `.pending { background: #eee }
            .win { background: green }
            .loss { background: #d9534f }
            .void { background: #999 }`
           }
          </style>
          { user.groupId === 0 && 
                <>No group associated, join a <a style={{paddingLeft: 5}} href='/leagues'>league</a></>
              }
                 { user.groupId > 0 && checkEmbargo() &&
               <div>
           { getEmbargoText() }
           </div>
}
              { user.groupId > 0 && !checkEmbargo() &&
              <> 
     
              <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow> 
            <TableCell>Game time</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Team</TableCell>
            <TableCell>Odds</TableCell>
            <TableCell>O/U</TableCell>
            <TableCell>Game</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {picks.map((row) => (
            <StyledTableRow
              key={row.pickNum}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={{ background: (row.pickType === 1 && row.GameResult === "Pending" ? '#FFFFBF' : getBackgroundColor(row.GameResult)) }}
            > 
              <TableCell scope="row">
                {row.GameStartTime}
              </TableCell>
              <TableCell scope="row">
                {row.userName}
              </TableCell>
              <TableCell scope="row">
                {row.selectedTeam}
              </TableCell>
              <TableCell scope="row">
                {row.odds}
              </TableCell>
              <TableCell scope="row">
                {row.overUnder}
              </TableCell>
              <TableCell scope="row">
              {(row.finalScore && row.finalScore?.length) ? row.finalScore : row.vsTeam} 
              </TableCell>
              <TableCell scope="row">
                {row.PickCreateTime}
              </TableCell>
              <TableCell scope="row">
                {row.GameResult} {row.pickType === 1 ? '*' : '' }
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  }
            </Grid> 
          </Container>
  );
}
