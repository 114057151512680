import { PaperProps, Paper, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Badge, TextField } from '@mui/material';
import React from 'react'; 
import Draggable from 'react-draggable';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function JoinDialog(args: any) {
  const [open, setOpen] = React.useState(args.open);
  const [min, setMin] = React.useState(args.min ? args.min : 500);
  const [max, setMax] = React.useState(args.max ? args.max : 1000);
  const [groupId] = React.useState(args.groupId ? args.groupId : 0);
  const [buyIn, setBuyIn] = React.useState(args.buyIn ? args.buyIn : 500);
 
  const [arg] = React.useState(args);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div> 
        <a href="#" onClick={handleClickOpen}>join</a>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Join League
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You must specify a Buy In for this league. The value must be between: <br />
            <br />
            { min } - { max } <br /> <br />
            <TextField
        id="outlined-uncontrolled"
        label="Buy in"
        value={buyIn}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
             setBuyIn(event.currentTarget.value)
          }}
      /><div>&nbsp;</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={async () => { 
            await arg.joinLeague(buyIn, groupId)
            handleClose();
          }} color="primary">
            Join
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
