import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card'; 
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button'; 
import { TextField } from '@mui/material';
import Modal from './smack.dialog'
import { apiRequest } from '../../../utils/apiRequest';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function BasicCard() {

    const [data, setData] = React.useState('');
    const [open, setOpen] = React.useState(false); 
 
    const [user] = React.useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : {});
    const click = async (e: any) => {
      await apiRequest.sendRequest('api/v1/dashboard/smack/save', 'post', { smack: data }) 
      window.location.href = window.location.href
    }

    const change = (e: any) => {
        const value = e.target.value;
 
        setData(value)
    }

  return (
    <Card sx={{   }}>
      <CardContent>
      { user.groupId > 0 && 
      <>
      <TextField
          id="filled-multiline-flexible"
          label="Enter smack talk here"
          multiline
          fullWidth
          rows={4}   
          value={data} 
          onChange={change}
        /> 
<div>&nbsp;</div>
<Button variant="outlined" onClick={click}>Send</Button>
</>
}
      </CardContent> 
    <Modal open={open} isOpen={() => open } isClosed={() => setOpen(false)} />
    </Card>
  );
}