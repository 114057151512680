 

import { IconButton, Badge } from "@mui/material";
import PickItem, { CartItemType } from "./PickItem";
import { Wrapper } from "./Picks.styles";
import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
type Props = {
  cartItems: any[];
  addToCart: (clickedItem: CartItemType) => void;
  removeFromCart: (id: number) => void;
};

const Cart = ({ cartItems, addToCart, removeFromCart }: Props) => {
  const [selected] = React.useState([])

  return (
    <Wrapper>
        <br /> <br /> <br />
      
      {cartItems.length === 0 ? <p>No picks selected</p> : null}
      {cartItems.map((item, key) => (
         <div style={{ background: (item.alt ? 'yellow' : '' )}}>
             <div>{ item.game }</div>
             <div>{ item.odd }</div>
             <div style={{ float: 'right', position: 'relative', right: 10, top: -45 }}>
            
                    <IconButton color="inherit" onClick={() => {}} style={{ position: 'relative', top: 10, right: 10, zIndex: 9999}}>
                        
                            <DeleteIcon onClick={() => { 
                                removeFromCart(key)
                            } } /> 
                    </IconButton>
                    </div>
                    <hr />
         </div>
      ))}
     
    </Wrapper>
  );
};

export default Cart;