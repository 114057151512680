const authCheck = {
    isAuthValid: (user: any) => {
   
        return true;
    },

    isAdmin: (user: string) => {
        if(!user){
            return false;
        }
        return user?.toLowerCase() === 'stiphel' || user?.toLowerCase() === 'snack'
    }
}

export { authCheck }