import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { IGetDashboard } from '../interfaces';
import { useEffect } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, CardContent, Typography } from '@mui/material';

interface IStatsArgs {
  info: IGetDashboard
} 

export default function FixedContainer(args: IStatsArgs) {
  const [data, setData] = React.useState({} as any);

  useEffect(()=>{
    const run = async() => setData(args.info)
    run();
}, [args]) // <-- empty dependency array

const createData = (name: string,value: number) => {
  return { name, value };
}

const rows = [
  createData('Remaining picks', args.info.remaining), 
  createData('Total wins', args.info.totalWins),
  createData('Total losses', args.info.totalLosses),
  createData('Pending picks', args.info.pending)
]; 

  return (<React.Fragment>
    <CardContent>
    <Typography sx={{ 'padding-left': 10, fontSize: 18, color: '#333', 'background-color': '#f5f5f5', 'border-color': '#ddd' }} color="text.secondary" gutterBottom>
    My Activity

    </Typography>
    <TableContainer component={Paper}>
      <Table sx={{  }} aria-label="simple table"> 
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.value}</TableCell> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
 
    </CardContent> </React.Fragment>
  );
}