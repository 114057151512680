
import Home from '../Dashbaord/Home/Home'
import {  Box, CssBaseline, ThemeProvider, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";
import axios, { AxiosRequestConfig } from "axios";
import { mdTheme, renderAppBar, renderDrawer } from "../Dashbaord/common";
import Smack from "../Dashbaord/Smack/Smack";
import Odds from '../Dashbaord/Odds/Odds'
import Standings from "../Dashbaord/Standings/Standings";
import Profile from "../Dashbaord/Profile/Profile";
import Rules from "../Dashbaord/Rules/Rules";
import MyPicks from "../Dashbaord/MyPicks/MyPicks";
import AllPicks from '../Dashbaord/AllPicks/AllPicks'
import { authCheck } from '../../utils/authCheck'
import Scoring from '../Admin/Scoring/Scoring'
import Users from '../Admin/Users/Users';
import Leagues from '../Dashbaord/Leagues/Leagues';
import Bot from '../Admin/Bot/Bot';
import AdminLeagues from '../Admin/Leagues/AdminLeagues';

import auth from '../../utils/authRedirect'
import NcaaScores from '../Admin/Bot/NcaaScores';
import NflScores from '../Admin/Bot/NflScores';

function App(args: any) {
  const [user, setUser] = useState(undefined as any)
  const [leagues, setLeagues] = useState([] as any[])
  const [width, setWidth] = React.useState<number>(window.innerWidth);

  const [open, setOpen] = React.useState(true);
  const isMobile = width <= 1000;

  let userCache = localStorage.getItem('user') as any
  if(!user && userCache){
    userCache = JSON.parse(userCache)
    setUser(userCache)
    const leagues = userCache?.leagues;
    setLeagues(leagues)
  }

  auth.setupInterceptors(); 

  useEffect(() => {
    const isValid = authCheck.isAuthValid(user); 
 
    if(!isValid){
      navigate('login')
    }
  }, user)

  const navigate = (destination: string) => {
      window.location.href = `/${destination}`
  }


  return (
    <ThemeProvider theme={mdTheme}>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline /> 
      { renderAppBar({ setOpen, isMobile, user, leagues }) }
      { renderDrawer({ setOpen }) }
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        { args.path === 'dashboard' && 
          <Home user={ user } /> }
           { args.path === 'smack' && 
          <Smack user={ user } /> }
            { args.path === 'odds' && 
          <Odds user={ user } /> }
            { args.path === 'profile' && 
          <Profile user={ user } /> }
            { args.path === 'standings' && 
          <Standings user={ user } /> } 
          { args.path === 'rules' && 
          <Rules user={ user } /> }
          { args.path === 'picks' && 
          <AllPicks user={ user } /> } 
          { args.path === 'myPicks' && 
          <MyPicks user={ user } /> }
   { args.path === 'admin/scoring' && 
          <Scoring user={ user } /> }
             { args.path === 'admin/bot' && 
          <Bot user={ user } /> }
             { args.path === 'admin/users' && 
          <Users user={ user } auth={args.auth} /> }
                       { args.path === 'leagues' && 
          <Leagues user={ user } /> }
                  { args.path === 'admin/leagues' && 
          <AdminLeagues user={ user } /> }
          { args.path === 'admin/bot/ncaa/scores' && 
            <NcaaScores user={ user } /> }
            { args.path === 'admin/bot/nfl/scores' && 
            <NflScores user={ user } />
          }
  </Box>
      </Box>
    </ThemeProvider>);
}

export default App

 