import * as React from 'react'; 
import Paper from '@mui/material/Paper'; 
import axios, { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import {  Avatar, Badge, Button, Container, Drawer, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSearchParams } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Picks from './Picks';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { apiRequest } from '../../../utils/apiRequest';
import Progress from './Progress'


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type Anchor = 'top' | 'left' | 'bottom' | 'right';

  export default function DashboardContent(args: any) {
  const [odds, setOdds] = React.useState([] as any[]);
  const [open, setOpen] = React.useState(true);
  const [league, setLeague] = React.useState({} as any);
  const [picks, setPicks] = React.useState([] as any[]);
  const [user, setUser] = React.useState(args.user as any)
  const [value, setValue] = React.useState(0);
  const [className, setClassName] = React.useState([] as any);
  const [searchParams, setSearchParams] = useSearchParams();
  const [cartOpen, setCartOpen] = React.useState(false);
  const [selected, setSelected] = React.useState([] as any[])
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [width, setWidth] = React.useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  } 
  const isMobile = width <= 850;

    useEffect(()=>{  

        const getDashbaordData = async() => await getOdds();
        getDashbaordData();

        setUser(args.user)
        const league = args.user.leagues.filter((league: any) => league.groupId === user.groupId)
        setLeague(league.length ? league[0] : {})
      console.log(league)

    }, [args]) // <-- empty dependency array

 

  const getOdds = async () => { 
    const sport = searchParams.get("sport")
    const result = await apiRequest.sendRequest(`api/v1/odds/get?sport=${sport}`, 'post') 

    const odds = result.odds.map((odd: any, index: number) => {
      const { Date: dateTime } = odd;
      return {
        ...odd,
        Date: new Date(parseInt(dateTime.replace("/Date(", '').replace(")/", ''))),
        id: index
      }
    })
    setOdds(odds)
  }
 

   
  const addPick = async() => {
      
    const picks = selected.map((select) => {
      const { row, type } = select;
      let value = getFormattedPickValue(row, type);
      let odds = '';
      let overUnder  = '-'
      if(type == 0){
        value = row.Game
        odds = row.Handicap;
      }
      if (type ==1){
        value = row.Opponent
        odds = row.HandicapOpponent;
      }
      if(type == 3){
        overUnder = row.TotalPoints
      }
      if(type == 2){
        overUnder = row.TPOpp
      } 
      const sport = searchParams.get("sport")
      
      const pick = {
        gameTime: row.Date,
        dateShortText: row.DateShortText,
        selectedTeam: type < 2 ? value : '-',
        odds:  type < 2 ? odds : '-',
        overUnder,
        groupId: league.groupId,
        vsTeam: row.VSTeam,
        pickType: !row.selected?.length ? 0 : 1,
        selectedOddIndex: 2,
        comments: `{"s": "${sport === '2' ? 'nfl' : 'ncaa'}", "r": "` + row.Rotation.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"}'
      }; 
      return pick;
    })
 
    const games = [] as any[];
    const unique = picks.filter((pick) => {
      if(games.indexOf(pick.vsTeam) == -1){
        games.push(pick.vsTeam)
        return true;
      }
      return false;
    })
 
     await apiRequest.sendRequest(`api/v1/user/picks/save`, 'post', { picks: unique }) 
    window.location.href = window.location.href
  }

  const getSelectedState = (row: any, type: number) => {    
    const isAlt = row.selected?.length > 0;
    const found = selected.filter((item: any) => item.game === row.VSTeam && item.type === type)
    return found.length ? (!isAlt ? '#9ED2BE' : '#FFFFBF') : ''
  } 

  const handleSelectPick = (row: any, target: any, type: number) => {
    const found = selected.find((item) => item.game === row.VSTeam)


    if(found){
      if(found.type === type){
        // same item clicked
        selected.splice(found.index, 1)
        target.style.background = '' 
      }
      else {
        // new item clicked for same game, remove old, add new
        found.target.style.background = '' 
        selected.splice(found.index, 1);
        
        selected.push({ game: row.VSTeam, odd: getFormattedPick(row, type), type, index: selected.length, row, target, reset: new Date().getTime() })
        target.style.background = getSelectedState(row, type) 
       } 
    }
    else {
      selected.push({ game: row.VSTeam, odd: getFormattedPick(row, type), type, index: selected.length, row, target, reset: new Date().getTime()  })
      target.style.background = getSelectedState(row, type)  
    } 
    setState({ ...state, ['right']: open })
  }

  const getFormattedPickValue = (row: any, type: number) => {
    let val = '';
    switch(type){
      case 0:  
        val = (`${row.Game} ${row.Handicap}`)
        break;
      case 1:  
      val = `${ row.Opponent } ${ row.HandicapOpponent }`  
        break;
      case 2:  
      val = `${ row.TPOpp }`  
        break;
      case 3:  
      val = `${ row.TotalPoints }`  
        break;
    }
    return val;
  }
  const getFormattedPick = (row: any, type: number) => {
    const value = getFormattedPickValue(row, type);
    return <>
      { value }
    </>
  }

  const getItemType = (item: any) => {
    const { odds, overUnder, selectedTeam } = item;
    if(odds.trim() === '-'){
      return overUnder.indexOf("over") > -1 ? 2 : 3
    }
    else { 
      return selectedTeam.indexOf(item.vsTeam.split(' vs ')[1]) > -1 ? 1 : 0
    }
  }

  const getSelectedBackground = (row: any, type: number) => {
    const { disabledAway, disabledOver, disabledHome, disabledUnder, selectedOddIndex } = row;
    if(disabledAway && type === 0 && selectedOddIndex === 1) {
      return '#ccc'
    }
    if(disabledHome && type === 1 && selectedOddIndex === 4) {
      return '#ccc'
    }
    if(disabledOver && type === 2 && selectedOddIndex === 8) {
      return '#ccc'
    }
    if(disabledUnder && type === 3 && selectedOddIndex === 2) {
      return '#ccc'
    }
    return '';
    // let selected = row.selectedOddIndex;
    // if(row.selected.length > 1){ 
    //   selected = row.selected.find((item: any) =>  item.vsTeam === row.VSTeam && getItemType(item) === type) 
    // } 
    // const isAlt = selected?.pickType === 1;
     
    // const over = selected?.overUnder.toLowerCase().trim().split(' ')[0];
    // const under = selected?.overUnder.toLowerCase().trim().split(' ')[0];
    // if (row.disabled > 0){
    //   switch(type){
    //     case 0: 
    //       if(selected?.odds.trim() !== '-'){ 
    //         const team = selected?.selectedTeam.trim();
    //         const away = row.Opponent;
    //         return team === away.toString() ? (!isAlt ? '#ccc' : '#FFFFBF') : ''
    //       }
    //       return ''
    //     case 1:  
    //     if(selected?.odds.trim() !== '-'){ 
    //       const team = selected?.selectedTeam.trim();
    //         const home = row.Game;
    //         return team === home.toString() ? (!isAlt ? '#ccc' : '#FFFFBF') : ''
    //     }
    //     return ''
    //     case 2: 
    //     if(selected?.odds.trim() === '-'){  
    //       const home = row.TPOpp.toLowerCase().trim().split(' ')[0]
    //       return over === home ? (!isAlt ? '#ccc' : '#FFFFBF') : ''
    //     }
    //     return ''
    //     case 3: 
    //     if(selected?.odds.trim() === '-'){  
    //       const home = row.TotalPoints.toLowerCase().trim().split(' ')[0]
    //       return under === home ? (!isAlt ? '#ccc' : '#FFFFBF') : ''
    //     }
    //     return ''        
    //   }
    // }
    //return ''
  }

  const checkDisabled = (row: any, type: number) => {
    const { disabledAway, disabledOver, disabledHome, disabledUnder } = row;
    if(disabledAway && type === 0) {
      return true
    }
    if(disabledHome && type === 1) {
      return true
    }
    if(disabledOver && type === 2) {
      return true
    }
    if(disabledUnder && type === 3) {
      return true
    }
    if(disabledAway || disabledOver || disabledHome || disabledUnder){
      return true;
    }
    // if(row.selected){
    //   return row.selected.some((pick: any) => {
    //     let { odds, overUnder, selectedTeam } = pick;
    //     odds = odds.trim();
    //     overUnder = overUnder.trim();
    //     const { Opponent, TPOpp, TotalPoints, HandicapOpponent, Handicap, Game } = row; 
    //     switch(type){
    //       case 0:  
    //       if(odds === '-'){
    //         return overUnder.indexOf(".") > -1
    //       } 
    //       return true
    //         break;
    //       case 1:  
    //       if(odds === '-'){
    //         return overUnder.indexOf(".") > -1
    //       }
    //       return true
    //         break;
    //       case 2:  
    //       if(overUnder === '-'){
    //         return odds.indexOf(".") > -1
    //       } 
    //       return `${TPOpp }` === overUnder || `${TotalPoints }` === overUnder 
    //         break;
    //       case 3:
    //         if(overUnder === '-'){
    //           return odds.indexOf(".") > -1
    //         }
    //       return `${TotalPoints }` === overUnder || `${TPOpp }` === overUnder
    //         break;
    //     }
    //   })
    // }
    return false;
  }

  const removeFromCart = (id: number) => { 
      selected[id].target.style.background = '' 
      selected.splice(id, 1);
      setState({ ...state, ['right']: open })
    } 


  const getMinWidth = () => {
    if(width < 500){
      return '33%'
    }
    else if (width < 600){
      return 300
    }
    return 300
  }

  const showOdds = () => {
    if(user.userName === 'stiphel'){
      return true;
    }
    if(user.groupId > 0 && odds.length){
      if(searchParams.get("sport") === "2"){
        return new Date(league.nflStart).getTime() < new Date().getTime(); 
      } 
      if(searchParams.get("sport") === "0"){
        return new Date(league.ncaaStart).getTime() < new Date().getTime(); 
      } 
    } 
    return false;
  }
 
  
  return (
   
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column', 
                  }}
                >  
                 
              <Drawer anchor="right" open={cartOpen} onClose={() => setCartOpen(false)}>
                <Picks
                  cartItems={selected}
                  addToCart={() => {}}
                  removeFromCart={removeFromCart}
                />
                <Button 
                fullWidth
                    variant="contained" 
                    onClick={addPick}
                    style={{ width: '90%', marginLeft: 20, marginBottom: 20}}
                  >
                    Save Picks
                  </Button>  
                  <div  style={{   paddingLeft: 20, border: '1px solid #999', background: '#ccc'}}>Pending pick</div>
          <div  style={{   paddingLeft: 20, border: '1px solid #999', background: 'yellow'}}>Alternate pick</div>
              </Drawer>
                      
              <IconButton color="inherit" onClick={() => setCartOpen(!cartOpen)} style={{ position: 'absolute', top: 10, right: 10, zIndex: 9999}}>
                <Badge badgeContent={selected.length} color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>

                 <TableContainer component={Paper} style={{ position: 'relative'}}>
                 { user.groupId < 1 && 
  <><div style={{ padding: 20 }}>No group associated, join a <a style={{paddingLeft: 5}} href='/leagues'>league</a></div></>
  }
  { user.groupId > 0  &&  !odds.length && 
                  <Progress />
                }
                { user.groupId > 0 && !showOdds() && 
<>
{ searchParams.get("sport") === "0" && 

<>Picks allowed on NCAA { new Date(league.ncaaStart).toLocaleDateString() } { new Date(league.ncaaStart).toLocaleTimeString() } <br /> </>
}
{ searchParams.get("sport") === "2" && 

<>Picks allowed on NFL { new Date(league.nflStart).toLocaleDateString() } { new Date(league.nflStart).toLocaleTimeString() } <br /> </>
}
</>
  }
{ showOdds() && 
<>
    <div style={{  float: 'right',   border: '1px solid #ccc', width: 400, padding: 20, display: (width < 850 ? 'none' : 'block') }}>
                  <h2 style={{ fontSize: 16, background: '#eee'}}>Selected picks</h2>
                 <hr />
                  { selected.map((item, key) => (
                    <div>
                    <div>{ item.game }</div>

                    <div>{ getFormattedPickValue(item.row, item.type)  }</div>
                    <div style={{ float: 'right', position: 'relative', right: 0, top: -40 }}>
                   
                    <IconButton color="inherit" onClick={() => {}} style={{ position: 'relative', top: 10, right: 10, zIndex: 999}}>
                        
                        <DeleteIcon onClick={() => { 
                            removeFromCart(key)
                        } } /> 
                </IconButton>
                    </div>
                    <hr />
                    </div>
                  ))}
                 
 <Button 
         fullWidth
            variant="contained" 
            onClick={addPick}
          >
            Save Picks
          </Button>  
          <div  style={{   paddingLeft: 20, border: '1px solid #999', background: '#ccc'}}>Pending pick</div>
          <div  style={{   paddingLeft: 20, border: '1px solid #999', background: 'yellow'}}>Alternate pick</div>
                </div>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ float: 'left', width: 702}}>
        <TableBody>
          {odds.map((row) => (
            <div>
            <TableRow>
              <TableCell colSpan={width > 850 ? 2 : 1} style={{ border: '1px solid #999', textAlign: 'center', background: '#ccc', width: (isMobile ? width - 125 : '100%') }}>{row.DateShortText} { row.VSTeam }</TableCell>
            </TableRow> 
            <StyledTableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          
            >
             
              <TableCell scope="row" style={{ display: (width < 850 ? 'none' : 'block'), padding: 10 }}>
                <div>
               <Avatar src={row.away?.imgURL} style={{ float: 'left'}} />  
              
               </div>  <br /> <br /> 
               <div>
               <Avatar src={row.home?.imgURL} style={{ float: 'left'}} /> 

               </div>
              </TableCell>
              <TableCell scope="row" style={{ padding: 10 }} >
               
              <Button
              fullWidth={isMobile}
              variant="outlined"   
              style={{ minWidth: getMinWidth(), fontSize: 14, background: getSelectedBackground(row, 0) }}
               onClick={(event) => { 
                handleSelectPick(row, event.currentTarget, 0)
              }}
              disabled={checkDisabled(row, 0)}
            >
            {row.Game}  { row.Handicap }  { row.selected?.length && row.selected[0].selectedTeam === row.Game ? `(${row.selected[0].odds.trim()})` : '' } 
            </Button> 
            { !isMobile ?
            <Button 
           fullWidth={isMobile} 
          style={{ minWidth: getMinWidth(), fontSize: 14 , marginLeft: (!isMobile ? 20 : 0), background: getSelectedBackground(row, 3)  }}  
            variant="outlined" 
            onClick={(event) => {
              handleSelectPick(row, event.currentTarget, 3)
            }}
            disabled={checkDisabled(row, 3)}
          >
            { row.TotalPoints }
          </Button>  
         : '' }
            <br />
            <Button
       fullWidth={isMobile}
      style={{ minWidth: getMinWidth(), fontSize: 14, background: getSelectedBackground(row, 1) }}
            variant="outlined" 
            onClick={(event) => {
              handleSelectPick(row, event.currentTarget, 1)
            }} 
            disabled={checkDisabled(row, 1)}
          >
           {row.Opponent}  { row.HandicapOpponent } { row.selected?.length && row.selected[0].selectedTeam === row.Opponent ? `(${row.selected[0].odds.trim()})` : '' } 
          </Button> 
          { isMobile ?
          <Button 
           fullWidth={isMobile} 
          style={{ minWidth: getMinWidth(), fontSize: 14 , marginLeft: (!isMobile ? 20 : 0), background: getSelectedBackground(row, 3)  }}  
            variant="outlined" 
            onClick={(event) => {
              handleSelectPick(row, event.currentTarget, 3)
            }}
            disabled={checkDisabled(row, 3)}
          >
            { row.TotalPoints }
          </Button>  
       : '' }
          <Button
            fullWidth={isMobile}
            style={{ minWidth: getMinWidth(), marginLeft: (!isMobile ? 20 : 0), fontSize: 14, background: getSelectedBackground(row, 2) }}
            variant="outlined" 
            onClick={(event) => {
              handleSelectPick(row, event.currentTarget, 2)
        
            }} 
            disabled={checkDisabled(row, 2)}
          >
            { row.TPOpp }
          </Button>
          
              </TableCell>
           
            </StyledTableRow>
            </div> 
          ))}
        </TableBody>
      </Table>
      </>
  }
                </TableContainer>

                </Paper>
              </Grid>
              
          </Container> 
  );
}
 