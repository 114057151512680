import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'; 
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab'; 

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  export default function DashboardContent(args: any) {
  const [user, setUser] = React.useState(args.user as any)
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
   
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
             
              {/* Recent Deposits */}
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column', 
                  }}
                >  
                            <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
         
          <Tab label="Pick 60 - NFL NCAA Football" {...a11yProps(0)} /> 
          <Tab label="Pick 18 - March Madness" {...a11yProps(1)} />
        </Tabs>
      </Box>
    
      <TabPanel value={value} index={0}>
      Welcome back American football handicappers!
      <br /><br />
This is the (???)st incarnation of our football picks pool.
<br /><br />
This year there are two pools<br /><br />
Low roller: $250 - $500<br /> 
High roller: $1500 - $3000 <br /> 
<br /> 
Of the hundreds of upcoming NCAA and NFL football games, pick your own subset of 60 games against the spread or on the over/under.
<br /><br />
The pool format is designed to reward pure handicapping skill. Sixty picks are enough to be statistically significant, but not so many as to force you into making marginal picks. The bettor has maximum flexibility. You can pick games early in the week to take advantage of potential/actual line movements. You can pick more games early in the season. You can wait for the bowl games and pro playoffs to make most of your picks. You can emphasize college or pro. You identify the best value, and this pool allows you to go after it.
<br /><br />
You will be able to pick any team's next game, however, no picks can be more than 1 game out. If you somehow are able to do this we will void the pick. 
<br /><br />
NFL preseason games are <b>not allowed</b> and will be <b>voided</b> if selected. 
<br /><br />
To join the pool you must register or login, join the current pool and declare an entry amount by the deadline. Amounts will vary.
<br /><br />
You are allowed to join both pools. 
<br /><br />
The pool pick site is here:<br /> 
https://picksixty.com/
<br /><br />
If this is your first time to the p60 site, you can try self-registration on the Register tab. Enter a username, a password, the registration code and click the blue button. If that doesn't work you can send Snack and stiphel an email with your preferred username and we'll get you ready for the site manually.
<br /><br />
 
**Once you're in, verify your buy-in amount on the My Profile page.**<br /><br />
Your buy-in must be between <b>$250 - $500</b> or <b>$1500 - $3000</b>. <br /><br />You will be automatically assigned the minimum buy in once you register for the pool. 
<br /><br />
The next time you come back to the site you can use the Login tab to get into the site.
<br /><br />
On the pickem site go to the NCAA Odds and NFL Odds pages to enter picks. You cannot rescind any picks. All picks are final once submitted. If you submit a pick on the Panthers and 10 minutes later it's announced that C. McCaffrey - RB is Out (Chaffed Groin Muscle), you are stuck with the pick.
<br /><br />
You will not be allowed to view other participants' picks until after kickoff, i.e. you may not copy others' picks. Right after kickoff you may log in and go to the Everyone's Picks page to see the teams other entrants picked so you'll know which ones to root for or against.
<br /><br />
You cannot win more than one pick in any single game. If you pick both the spread and the O/U on the same game, at most one result will count. You may pick both in the same game, but the 2nd pick will count only if the 1st pick pushes.
<br /><br />
The entrant with the most correct picks after the Super Bowl wins. There are no tiebreakers. If two or more entrants tie, the pot will be split. If you wager less than others in the pool and win, you will get your pro-rata split of the pot as done in poker.
<br /><br />
Bonus pool: 20% of your entry amount goes into a bonus pool. This bonus will be awarded to the entrant with the most correct picks made after their first 40 picks. Thus, if someone starts out 0-40, they still have a chance to win some pool money. Bonus standings are based on each entrant's picks numbered 41 through 60. Just like in the 80% pick 60 pool, pushed & voided picks do not count in the bonus pool, and for ties at the top of the bonus standings pots will split. Your picks (e.g. 39th, 40th, 41st, 42nd) are primarily ordered by the time at which you submit them, not their kickoff times. Only in the case you submit two or more picks at the same time from the same bet slip window then those picks will be ordered by their kickoff times. If two or more of those same-submit-time picks have the same kickoff time then those same-submit-time-same-kickoff-time picks will be ordered by the road team name alphabetically. There are cases when an entrant's unofficial bonus standings can change unexpectedly for better or worse. Say on a Friday I submit my 40th pick on the MNF NFL game. On Saturday I submit my 41st pick on a Saturday NCAA game. Hot damn I win the NCAA pick and the bonus standings will unofficially show me at 1-0. Darn it two days later the MNF game ends in a push, my 41st pick becomes my 40th pick and my bonus standings revert to 0-0.
<br /><br />

The pool is invitation only. We invite only those whose money is good and whose reputations are even better.
<br /><br />
Updates and standings will be posted periodically on the pickem site. All lines are pulled from Pinnacle Sports. <br /> 
https://www.pinnacle.com/en/football/ncaa/matchups#period:0<br /> 
https://www.pinnacle.com/en/football/nfl/matchups#period:0
<br /><br />
All losers are required to pay within 2 weeks after the Superbowl. Winner payout information will be posted once final results are in. Don't pay until you get payout information. 
<br /><br />
****
<br /><br />
For typical year rule details click on the Rules tab.
<br /><br />
Hosting is no longer free. At the end of this season we will deduct a small operations fee from the top money winners.
<br /><br />
If you want to use a different email address (i.e. non-work) than the one used here, let us know. If you would like to be removed from this list let us know. 
<br /><br />
For any questions on rules, invites and results you may email or text Snackmish. Snack is the ultimate decider on game results. <br /><br />
sullysnack@yahoo.com<br /> 
m: 303-319-3188
<br /><br />
For any technical issues with registration, sign up, picks, scoring, buy in or the site in general please email Snackmish & stiphel.<br /><br />
sullysnack@yahoo.com<br /> 
me@adamfeldman.com
<br /><br />

Discord channel<br /> 
https://discord.com/channels/610150180511154186/610150180511154188
<br /><br />
Good luck!
<br /><br />
-Snackmish
      </TabPanel>

      <TabPanel value={value} index={1}>
      Welcome back college basketball handicappers!
<br /><br />
There are 67 NCAA men's basketball tournament games upcoming.
<br /><br />
Make picks against the spread or the over / under on 18 of those games. Picks on all games are scored equally. 1 point for each win. 0 points for each loss.
<br /><br />
The entrant with the most correct picks wins. If two or more entrants tie for most correct picks, then the pot will be split. No tiebreakers.
<br /><br />
http://www.picksixty.com/<br />
The site is friendly to both desktop and mobile web browsers.
<br /><br /> 
At first everyone must click on the Register tab...
<br /><br />
Once you're in, verify your buy-in amount on the My Profile tab. Your stake should be something between $300 and $1500. Payouts are like poker all-ins. If you put up less than some other entrants, you need to pick better than them or as good as them to win anything.
<br /><br />
The next time you come back to the site you can use the Login tab to get in.
<br /><br /> 
The pickem site slurps lines from the Pinnacle column from the pinnacle apis:<br />
https://guest.api.arcadia.pinnacle.com/0.1/leagues/493/markets/straight<br />
https://guest.api.arcadia.pinnacle.com/0.1/leagues/493/matchups<br />
<br /> 
http://www.pinnaclesports.com/ 
<br /><br />
On the pickem site go to the NCAA Odds page to enter picks. You cannot rescind any picks. All picks are final once submitted. If you submit a pick on UNC and 10 minutes later it’s announced their entire starting line-up has been suspended (failed their papier-mâché credit class) you are stuck with the pick.
<br /><br />
You will not be allowed to view other participants' picks on any game until after tip off, i.e. you may not copy others' picks. A minute after the listed tip off you may review the Everyone's Picks page to see the teams other entrants picked so you'll know which ones to root for or against.
<br /><br />
Picks on this Tuesday's and Wednesday's first round games will be accepted.
<br /><br />
Picks on NIT/other/women's games will be voided. You may practice making picks with any non-NCAA tourney games listed on the NCAA Odds page. Picks on non-NCAA tourney games will not count toward your allotment of 18 picks.
<br /><br />
No first half or halftime wagers. No money line wagers. Only entire game point spread picks and over / under picks are OK. Pushed picks are voided. Repick.*
<br /><br />
You cannot win both a point spread pick and an O/U pick on the same game. You may enter an alternate pick on the same game if the primary pick point spread or O/U is a whole number. In the case a primary pick pushes, if you entered an alternate pick for it then it will apply. Note that for any primary pick with a whole number point spread or O/U, you may make an alternate pick for it from the same game or from a different game.
<br /><br />
*If both your primary pick and alternate pick push, then you will receive half a win and half a loss for that pick. You do not repick double pushes. For example, say one of my picks is Tennessee -5 vs UTEP. Because the line is a whole number, and I really like the defenses in this game as well, I decide to pick an alternate: Tenn/UTEP under 145. If the final is Tenn wins by 6 or more, I win the primary pick and the alternate under pick is void and ignored.  If Tenn wins by 4pts or fewer or loses straight up, I lose the primary pick and the alternate pick is ignored. If Tenn wins by 5 like 74-69, the primary pick is void but I still win a pick on the under. If Tenn wins by 5 like 76-71, the primary pick is void but I lose the under. If Tenn wins 75-70 then it's a double push and I am credited 0.5 win and 0.5 loss.
<br /><br />
If you leave your 18th pick for the final game and your primary pick line is a whole number then you should pick an alternate as well.
<br /><br />
You may want to enter alternate picks for the two semifinal games so that you are not left with more than one pick going into the final. You can get credit for only one pick on any game.
<br /><br />
Updates and standings will be posted periodically on the pickem site.
<br /><br />
If you want me to use a different email address (i.e. non-work) than the one I used here, let me know. For any questions on rules, invites and results you may email or text Snackmish:
sullysnack@yahoo.com
m: 303-319-3188
<br /><br />
For any technical issues with the site please email Snackmish and stiphel:<br />
sullysnack@yahoo.com<br />
me@adamfeldman.com<br />
<br /><br />
Good luck!
<br /><br />
-Snackmish
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>    
                </Paper>
              </Grid>
              
          </Container>
  );
}

