

import * as React from 'react'; 
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';   
import { apiRequest } from '../../../utils/apiRequest';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, styled } from '@mui/material';
import YearSelect from './YearSelect';
import WeekSelect from './WeekSelect';
import { configs } from '../../../utils/configs'
const baseUrl = configs.baseUrl.length ? configs.baseUrl : 'localhost'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  export default function NcaaScores(args: any) {
  
    const [scores, setScores] = React.useState([] as any[]);
    const [year, setYear] = React.useState('2023');
    const [week, setWeek] = React.useState('01');
  const [user, setUser] = React.useState((args.user ? args.user : { }) as any)  
  
  React.useEffect(()=>{  
    
    const get = async () => {
      await getScores(0, year, week)
    }
    get();
  
    
}, [user]) // <-- empty dependency array


const getScores = async (type = 0, year:string, week: string) => {  
  const result = await apiRequest.sendRequest(`api/v1/scores/ncaa`, 'post', { type, year, week });
  setScores(result)
}
const onYearSelect = (value: string) => { 
        getScores(0, value, week);
}

const onWeekSelect = (value: string) => { 
        getScores(0, year, value);
}

  return (
   
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
             
              {/* Recent Deposits */}
              <Grid item xs={12} md={6} lg={6}>
             <YearSelect onSelect={onYearSelect}/>
             <WeekSelect onSelect={onWeekSelect}/>

<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
  <TableHead>
    <TableRow>
      <TableCell>gameID</TableCell>
      <TableCell>Game time</TableCell>
      <TableCell>gameState</TableCell>
      <TableCell>title</TableCell> 
    </TableRow>
  </TableHead>
  <TableBody>
    {scores.map((row: any) => (
      <StyledTableRow
        key={row.game.gameID}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }} 
      >
        <TableCell component="th" scope="row">
          {row.game.gameID} 
        </TableCell>
        <TableCell scope="row">
         {row.startDate} {row.game.startTime}
        </TableCell>
        <TableCell scope="row">
          {row.game.gameState}
        </TableCell> 
        <TableCell scope="row">
        {row.game.away.names.short} vs  {row.game.home.names.short}
        </TableCell> 
        <TableCell scope="row">
          {row.game.away.score} -  {row.game.home.score}
        </TableCell> 
      </StyledTableRow>
    ))}
  </TableBody>
</Table>
</TableContainer>
                 
                </Grid>
                
            </Container>
    );
  }
  