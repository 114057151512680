import * as React from 'react'; 
import { IGetDashboard } from '../interfaces';
import { useEffect } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, CardContent, Typography } from '@mui/material';

interface IStatsArgs {
  info: IGetDashboard
} 

export default function FixedContainer(args: any) {
   
  const [user, setUser] = React.useState(args.user as any)
  const [info, setInfo] = React.useState(args.info as any)
  const [leagues, setLeagues] = React.useState(args.user?.leagues as any[])  

  const createData = (name: string,value: string) => {
    return { name, value };
  }

  const setData = () => {
    const league = user.leagues.filter((league:any) => league.groupId === user.groupId);
  
    if(league?.length && new Date(league[0].ncaaStart).getTime() < new Date().getTime() && rows.length === 1){
      rows.push(createData('Payout for 1st', args.info.payout))
      rows.push(createData('Payout for Bonus', args.info.bonus))
      rows.push(createData('Total Payout', args.info.totalPayout))
      if(league.length){
        rows.push(createData('Pool begins', `${new Date(league[0].ncaaStart).toLocaleTimeString()} ${new Date(league[0].ncaaStart).toLocaleDateString()}`))
      }
    } 
    else {
      if(league.length){
        rows.push(createData('Embargo', `Payout visible ${new Date(league[0].ncaaStart).toLocaleTimeString()} ${new Date(league[0].ncaaStart).toLocaleDateString()}`))
      }
    }
} 
 
  const rows = [createData('Buy in', args.info?.buyin)] as any[]
  setData()

  useEffect(()=>{ 
    if(!rows.length && args.info.payout){
      rows.push(createData('Buy in', args.info.buyin));
      //setData(args.info);
    }
  }, [args]) // <-- empty dependency array

  return (<React.Fragment>
    <CardContent>
    <Typography sx={{ 'padding-left': 10, fontSize: 18, color: '#333', 'background-color': '#f5f5f5', 'border-color': '#ddd' }} color="text.secondary" gutterBottom>
    My Payout Possibilities

    </Typography>
    <TableContainer component={Paper}>
      <Table sx={{  }} aria-label="simple table"> 
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.value}</TableCell> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
 
    </CardContent> </React.Fragment>
  );
}