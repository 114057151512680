import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

export default function ResponsiveDialog(args: any) {
  const [open, setOpen] = React.useState(args.open);
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isOpen = args.isOpen;
 

  const handleClose = () => {
    args.isClosed() 
  };
 
  useEffect(()=>{  
    if(isOpen() != open){
        setOpen(isOpen())
    }
}, [args]) // <-- empty dependency array
  

  return (
    <div> 
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Post Smack Talk?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to post this? 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Nope
          </Button>
          <Button onClick={handleClose} autoFocus>
            Yep
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}