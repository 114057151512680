import * as React from 'react'; 
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';   
import axios, { AxiosRequestConfig } from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { apiRequest } from '../../../utils/apiRequest';
import JoinDialog from './JoinDialog';
import EditDialog from './EditDialog';
import Progress from '../Odds/Progress';
 
  export default function Leagues(args: any) {
  
    const [open, setOpen] = React.useState(false);
    const [user, setUser] = React.useState(args.user as any) 
    const [leagues, setLeagues] = React.useState([] as any[]);
    const [userLeagues, setUserLeagues] = React.useState([] as any[]);

    const [width, setWidth] = React.useState<number>(window.innerWidth);
 
    const isMobile = width <= 515;

      React.useEffect(()=>{ 
          const getLeaguesAsync = async() => await getLeagues();
          getLeaguesAsync(); 

          setUser(args.user)
      }, [args]) // <-- empty dependency array
   
  
    const getLeagues = async () => { 
        const leagues = await apiRequest.sendRequest('api/v1/dashboard/leagues', 'post') 
         
        const userLeagues = await apiRequest.sendRequest('api/v1/dashboard/user/leagues', 'post') as any[]
        setUserLeagues(userLeagues)

        const update = leagues.filter((league: any) => !userLeagues.some((leag: any) => leag.groupId == league.groupId) ).concat(userLeagues)
        setLeagues(update)
    } 

    const joinLeague = async (buyIn: number, groupId: number) => {
        await apiRequest.sendRequest('api/v1/dashboard/user/leagues/join', 'post', { buyIn, groupId }) 

        const user = JSON.parse(localStorage.getItem('user') as string)
        user.groupId = groupId;
        
        const userLeagues = await apiRequest.sendRequest('api/v1/dashboard/user/leagues', 'post') as any[]
    
        user.leagues = userLeagues

        localStorage.setItem('leagues', JSON.stringify(userLeagues))

        localStorage.setItem('user', JSON.stringify(user))

        window.location.href = '/dashboard'
    }
  
    const leaveLeague = async (groupId: number) => {
        const update = await apiRequest.sendRequest('api/v1/dashboard/user/leagues/remove', 'post', { groupId }) 
        const user = JSON.parse(localStorage.getItem('user') as string)
        user.groupId = update.newGroupId;
        localStorage.setItem('user', JSON.stringify(user))
        window.location.href = '/leagues'
    }

    const getBuyInForGroupId = (groupId: number, buyIn: number) => {
        const found = userLeagues.filter((league) => league.groupId === groupId)
     
        if(found.length){
            return found[0].buyIn
        }
        return buyIn;
    }
  
  return (
   
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
             
              {/* Recent Deposits */}
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column', 
                  }}
                >   
                   { !leagues.length && 
                  <Progress />
                }
                <p>* NOTE - You can leave a league or change your buy in until the embargo, then it is locked.</p>
   <TableContainer component={Paper}>
      <Table sx={{ minWidth: (isMobile ? width - 125 : '100%') }} aria-label="simple table">
        <TableHead>
          <TableRow> 
            <TableCell style={{ display: (isMobile ? 'none' : ' ')}}>Embargo</TableCell>
            <TableCell>Name</TableCell>
            <TableCell style={{ display: (isMobile ? 'none' : ' ')}}>Max Picks</TableCell>
            <TableCell style={{ display: (isMobile ? 'none' : ' ')}}>Min Buy in</TableCell>
            <TableCell style={{ display: (isMobile ? 'none' : ' ')}}>Max Buy in</TableCell> 
            <TableCell>Buy in</TableCell> 
            <TableCell>&nbsp;</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {leagues.map((row) => ( 
            <TableRow key={ row.id }> 
              <TableCell scope="row" style={{ display: (isMobile ? 'none' : ' ')}}>
              {new Date(row.poolStart).toLocaleDateString() } {new Date(row.poolStart).toLocaleTimeString() }
              </TableCell>
              <TableCell scope="row">
                {row.name}
              </TableCell>
              <TableCell scope="row" style={{ display: (isMobile ? 'none' : ' ')}}>
                {row.maxPicks}
              </TableCell>
              <TableCell scope="row" style={{ display: (isMobile ? 'none' : ' ')}}>
                {row.minBuyin}
              </TableCell>
              <TableCell scope="row" style={{ display: (isMobile ? 'none' : ' ')}}>
                {row.maxBuyin}
              </TableCell> 
              <TableCell scope="row">
                {row.buyIn ? row.buyIn : 'N/A'}
              </TableCell> 
              <TableCell scope="row">
                { row.buyIn && (new Date(row.poolStart).getTime() > new Date().getTime()) && 
                    <EditDialog min={row.minBuyin} max={row.maxBuyin} open={open} joinLeague={joinLeague} groupId={row.groupId} leaveLeague={leaveLeague} style={{ float: 'left'}} buyIn={getBuyInForGroupId(row.groupId, row.minBuyin)}  />
                }
                { row.buyIn && (new Date(row.poolStart).getTime() < new Date().getTime()) && 
                     <a href='#' onClick={async () => {
                         await joinLeague(row.buyIn, row.groupId)
                     }}>select</a>
                }
                { !row.buyIn &&
                <> 
                    <JoinDialog min={row.minBuyin} max={row.maxBuyin} open={open} joinLeague={joinLeague} groupId={row.groupId} buyIn={getBuyInForGroupId(row.groupId, row.minBuyin)} />
                </> 
                }
              </TableCell> 
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                </Paper>
              </Grid>
              
          </Container>
  );
}
