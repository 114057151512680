import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';

export default function EditUser(args: any) {
  const [open, setOpen] = React.useState(false);
  const [userName, setUsername] = React.useState('');
  const [params] = React.useState(args);

  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState('');
  const [confirm, setConfirm] = React.useState('');
  const [first, setFirst] = React.useState('');
  const [last, setLast] = React.useState(''); 
  const [avatar, setAvatar] = React.useState(''); 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async () => {
    await params.updateUser({ userName, email, password, first, last, avatar }) 
    handleClose();
  }

  React.useEffect(() => {
    setUsername(params.user?.userName)
    setEmail(params.user.email)
    setFirst(params.user.firstName)
    setLast(params.user.lastName)
    setAvatar(params.user.avitar) 
  }, [userName])

  return (
    <div style={{ float: 'left', paddingRight: 5 }}>
       <a href='#' onClick={handleClickOpen}>edit</a>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Edit user: {userName}
        </DialogTitle>
        <DialogContent
        style={{ width: 600}}>
          <DialogContentText id="alert-dialog-description">
          <TextField
        id="outlined-uncontrolled"
        label="email"
        value={email}
        fullWidth
        onChange={(event: any) => {
            setEmail(event.currentTarget.value);
          }}
      /><div>&nbsp;</div>
      <TextField
        id="outlined-uncontrolled"
        label="password"
        value={password}
        fullWidth
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }}
      /><div>&nbsp;</div> 
      <TextField
        id="outlined-uncontrolled"
        label="first"
        fullWidth
        value={first}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFirst(event.target.value);
          }}
      /><div>&nbsp;</div>
      <TextField
        id="outlined-uncontrolled"
        label="last"
        value={last}
        fullWidth
        onChange={(event: any) => {
            setLast(event.target.value);
          }}
      /><div>&nbsp;</div>
      <TextField
        id="outlined-uncontrolled"
        label="avatar"
        value={avatar}
        fullWidth
        onChange={(event: any) => {
            setAvatar(event.currentTarget.value);
          }}
      /><div>&nbsp;</div> 

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdate} autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
