import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';  
import { Alert, Avatar, Button, Snackbar, Stack, TextField } from '@mui/material';
import { apiRequest } from '../../../utils/apiRequest';
import Confirmation from './Confirmation';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  export default function DashboardContent(args: any) {
  
  const [user, setUser] = React.useState((args.user ? args.user : { }) as any)
  const [avatar, setAvatar] = React.useState()
 
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState('');
  const [confirm, setConfirm] = React.useState('');
  const [first, setFirst] = React.useState(user.firstName);
  const [last, setLast] = React.useState(user.lastName); 
  const [open, setOpen] = React.useState(false);

    const [error, setError] = React.useState('')
    const [success, setSuccess] = React.useState('')

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
const onClick = async () => {
  setError('')
  if(password === confirm && (password.length === 0 || password.length >= 8)){ 
   
    await apiRequest.sendRequest('api/v1/user/profile/update', 'post', { user: {
      email, firstName: first, lastName: last, avitar: avatar, password
    } }) 
 
    const user = JSON.parse(localStorage.getItem('user') as string)
    user.firstName  = first;
    user.lastName  = last;
    user.email  = email;
    user.avitar  = avatar;
    localStorage.setItem('user', JSON.stringify(user))
    setSuccess('Profile updated')
    setPassword('')
    setConfirm('')

    setTimeout(() => {
      setSuccess('')
    }, 2500)
}
else {
  setError(`Password must be 8 characters and must match confirm`)
  return false;
}
  
}
 

React.useEffect(()=>{  
 setAvatar(user.avitar)
 setEmail(user.email)


}, [user]) // <-- empty dependency array

const handleChange = (event: any) => {
  setAvatar(event.currentTarget.value);
}
const handleEmailChange = (event: any) => {
  setEmail(event.currentTarget.value);
}

const getSnackBar = () => {
  return (
  <Stack spacing={2} sx={{ width: '100%' }}> 
    <Snackbar style={{ width: '100%' }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={() => {
      setOpen(false)
    }}>
      <Alert onClose={() => {
      setOpen(false)
    }} severity="success" sx={{ width: '100%' }}>
        This is a success message!
      </Alert>
    </Snackbar> 
  </Stack>) 
}

  return (
   
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
             
              {/* Recent Deposits */}
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column', 
                  }}
                >  
                { getSnackBar() }
                <div style={{ border: '1px solid red', padding: 20, display: (error.length ? 'block' : 'none') }} >
                  ERROR: { error }
                </div>
                <div style={{ border: '1px solid green', padding: 20, display: (success.length ? 'block' : 'none') }} >
                  Success: { success }
                </div>
                <div style={{ margin: '0 auto' }}>
   <Avatar
        alt="Remy Sharp"
        src={ avatar}
        sx={{ width: 200, height: 200,  }}
      /></div><div>&nbsp;</div>
    
      <TextField
        id="outlined-uncontrolled"
        label="email"
        value={email}
        onChange={handleEmailChange}
      /><div>&nbsp;</div>
      <TextField
        id="outlined-uncontrolled"
        label="password"
        value={password}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }}
      /><div>&nbsp;</div>
      <TextField
        id="outlined-uncontrolled"
        label="confirm"
        value={confirm}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setConfirm(event.target.value);
          }}
      /><div>&nbsp;</div>
      <TextField
        id="outlined-uncontrolled"
        label="first"
        value={first}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFirst(event.target.value);
          }}
      /><div>&nbsp;</div>
      <TextField
        id="outlined-uncontrolled"
        label="last"
        value={last}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setLast(event.target.value);
          }}
      /><div>&nbsp;</div>
      <TextField
        id="outlined-uncontrolled"
        label="avatar"
        value={avatar}
        onChange={handleChange}
      /><div>&nbsp;</div> 
  <Button variant="contained" fullWidth onClick={onClick}>Update</Button><br /><br /><br /><br /><br /><br />
                </Paper>
              </Grid>
            
          </Container>
  );
}
