import * as React from 'react'; 
import { useEffect } from 'react';
import { TableContainer, Paper, Table, TableRow, TableCell, TableBody, CardContent, Typography, Avatar } from '@mui/material';

 

export default function SmackList(args: any) {
  const [smack, setSmack] = React.useState([] as any); 

  useEffect(()=>{
    let smack: any[] = [];
    
    args?.smack?.map((item: any) => {
        const data = createData(<Avatar alt={ item.userName } src={ item.avatar } />, item.userName, `${item.smackTalk} - ${item.SmackCreated} ET`)
        smack.push(data)
    })    
    setSmack(smack)
}, [args]) // <-- empty dependency array

const createData = (avatar: Object, name: string, value: string) => {
  return { avatar, name, value };
}
 

  return (<React.Fragment>
    <CardContent>
    <Typography sx={{ 'padding-left': 10, fontSize: 18, color: '#333', 'background-color': '#f5f5f5', 'border-color': '#ddd' }} color="text.secondary" gutterBottom>
    Latest

    </Typography>
    <TableContainer component={Paper}>
      <Table sx={{  }} aria-label="simple table"> 
        <TableBody>
          {smack.map((row: any) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.avatar}
                {row.name}
              </TableCell>
              <TableCell align="right">{row.value}</TableCell> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
 
    </CardContent> </React.Fragment>
  );
}