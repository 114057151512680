import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'; 
import axios, { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { IGetDashboard, ISmack } from '../interfaces';
import Stats from './stats'
import Activity from './activity'
import Payout from './payout.ncaab'
import Leaders from './leaders' 
import SmackList from './smack.list' 
import SmackSend from './smack.send' 
import { apiRequest } from '../../../utils/apiRequest'

export default  function DashboardContent(args: any) { 
  const [data, setData] = React.useState({} as IGetDashboard);
  const [smack, setSmack] = React.useState([] as ISmack[]);
  const [user, setUser] = React.useState(args.user as any)
  const [leagues, setLeagues] = React.useState(args.user.leagues as any[]) 
  const [token] = React.useState(localStorage.getItem('token') as string)

  const [width, setWidth] = React.useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  } 
  const isMobile = width <= 768;

    useEffect(()=>{ 
      setUser(args.user)
     
      if(args.user.leagues.length){
        const getDashboardSmack = async() => await getSmack();
        getDashboardSmack();

        const getDashbaordData = async() => await getData();
        getDashbaordData();
      }
        

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

    }, [args]) // <-- empty dependency array

  const getData = async () => { 
    const result = await apiRequest.sendRequest('api/v1/dashboard/get', 'post') 
    result.payoutEmbargo = checkEmbargo();
    result.embargoText = getEmbargoText()
    setData(result)
  }

  const getSmack = async () => { 
    const result = await apiRequest.sendRequest('api/v1/dashboard/smack', 'post', { limit: 3 }) 
    setSmack(result) 
  }   

  const getEmbargoText = () => {
    const league = user.leagues.filter((league:any) => league.groupId === user.groupId);
    return (
      <>Payout visible { new Date(league[0].poolStart).toLocaleDateString() } { new Date(league[0].poolStart).toLocaleTimeString() }</>
    )
  }
   
  const checkEmbargo = () => {
    if(user.groupId > 0 && user.leagues.length){ 
      const league = user.leagues.filter((league:any) => league.groupId === user.groupId);
      return new Date(league[0].poolStart).getTime() > new Date().getTime();
    }
    return true
  }
  

  return (
    
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              { user.groupId === 0 && 
                <><div style={{ padding: 20 }}>No group associated, join a <a style={{paddingLeft: 5}} href='/leagues'>league</a></div></>
              }
              { user.groupId > 0 && 
              <>
         
              
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column', 
                  }}
                > <SmackList smack={smack}/>
                <Stats info={ data } />
                <Leaders info={ data } />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column', 
                  }}
                > 
                                <Payout info={data} leagues={leagues} user={user} />
                <Activity info={ data } />
                
                               
                </Paper>
              </Grid>
              </>
            }
            </Grid> 
          </Container>
  );
}

 