import * as React from 'react'; 
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';   
import { apiRequest } from '../../../utils/apiRequest';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import styled from 'styled-components';
import NcaaScores from './NcaaScores';
import { configs } from '../../../utils/configs'
const baseUrl = configs.baseUrl.length ? configs.baseUrl : 'localhost'

  export default function Leagues(args: any) {
  
  const [user, setUser] = React.useState((args.user ? args.user : { }) as any) 
  const [scores, setScores] = React.useState([] as any[]);
  

  React.useEffect(()=>{  
    
      const get = async () => {
        await getScores(0)
      }
      get();
    
      
  }, [user]) // <-- empty dependency array


  const getScores = async (type = 0) => {  
    const result = await apiRequest.sendRequest(`api/v1/scores/ncaa/scores`, 'post', {   });
    setScores(result)
  }

  const getFriendlyResult = (result: number) => {
    switch(result){
      case 1: 
        return 'Win'
      case 2: 
        return 'Loss'
      case 3: 
        return 'Push'
    }
  }

  const onClick = async () => {
    await apiRequest.sendRequest(`api/v1/scores/ncaa/scores/save`, 'post', {  });
    await getScores();
  }

  return (
   
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
             
              {/* Recent Deposits */}
              <Grid item xs={12} md={6} lg={6}>
             <a href='/admin/bot/ncaa/scores'>ncaa scores</a> | <a href='/admin/bot/nfl/scores'>nfl scores</a>

<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
  <TableHead>
    <TableRow>
      <TableCell>game start</TableCell>
      <TableCell>userName</TableCell>
      <TableCell>pick</TableCell>
      <TableCell>final score</TableCell> 
      <TableCell>groupId</TableCell> 
      <TableCell>result</TableCell> 
    </TableRow>
  </TableHead>
  <TableBody>
    {scores.map((row: any) => (
      <TableRow
        key={row.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }} 
      >
      <TableCell scope="row">
      {row.gameTime} 
      </TableCell> 
        <TableCell component="th" scope="row">
          {row.userName} 
        </TableCell>
        <TableCell scope="row">
        {row.selectedTeam.trim() === '-' ? '' : row.selectedTeam} {row.odds.trim() === '-' ? '' : row.odds} { row.overUnder.trim() === '-' ? '' : row.overUnder }
        </TableCell>
        <TableCell scope="row">
        {row.finalScore} 
        </TableCell> 
        <TableCell scope="row">
        {row.groupId} 
        </TableCell> 
         <TableCell scope="row">
        {getFriendlyResult(row.result)} 
        </TableCell>  
      </TableRow>
    ))}
  </TableBody>
</Table>
</TableContainer><br /><br /><br />
<Button variant="contained" fullWidth onClick={onClick}>Score</Button><br /><br /><br />

               
              </Grid>
              
          </Container>
  );
}
