import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from "./components/Login/Login";
import App from "./components/App/App";
  
const auth = (authUser: any, token: string) => {
  localStorage.setItem('user', JSON.stringify(authUser))
  localStorage.setItem('token', token)
  localStorage.setItem('cookie', authUser.auth)
  localStorage.setItem('leagues', JSON.stringify(authUser.leagues))
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login auth={auth}/>, 
  },
  {
    path: 'login',
    element:  <Login auth={auth}/>, 
  },
  {
    path: 'dashboard',
    element: <App path='dashboard' />
  },
  {
    path: 'smack',
    element: <App path='smack' />
  },
  {
    path: 'standings',
    element: <App path='standings' />
  },
  {
    path: 'odds',
    element: <App path='odds' />
  },
  {
    path: 'my-picks',
    element:<App path='myPicks' />
  },
  {
    path: 'picks',
    element: <App path='picks' />
  },
  {
    path: 'rules',
    element: <App path='rules' />
  },
  {
    path: 'profile',
    element: <App path='profile' />
  },
  {
    path: 'admin/scoring',
    element: <App path='admin/scoring' />
  },
  {
    path: 'admin/bot',
    element: <App path='admin/bot' />
  },
  {
    path: 'admin/bot/ncaa/scores',
    element: <App path='admin/bot/ncaa/scores' />
  },
  {
    path: 'admin/bot/nfl/scores',
    element: <App path='admin/bot/nfl/scores' />
  },
  {
    path: 'admin/users',
    element: <App path='admin/users' auth={auth} />
  },
  {
    path: 'leagues',
    element: <App path='leagues' />
  },
  {
    path: 'admin/leagues',
    element: <App path='admin/leagues' />
  }
]);

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById('root'),
);

