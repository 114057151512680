import { Api } from "@mui/icons-material";
import axios, { AxiosRequestConfig } from "axios";
import { configs } from './configs'
const baseUrl = configs.baseUrl.length ? configs.baseUrl : 'localhost'

class ApiRequest {
    async sendRequest (path: string, method: string, data?: any) {
        const cookie = localStorage.getItem('cookie');
        const token = localStorage.getItem('token')

        const url = `${baseUrl.indexOf('localhost') > -1 ? 'http' : 'https'}://${baseUrl}/${path}`

        const config = {
            method,
            url,
            headers: {  
                'accept': 'application/json, text/plain, */*',  
                'Authorization': `Bearer ${token}` 
            },
            data: { cookie, ...data }
            
        } as AxiosRequestConfig<any>;
        const result = await axios(config)
        return result?.data ?? [];
    }
}
const apiRequest = new ApiRequest();
export { apiRequest }