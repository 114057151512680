import * as React from 'react'; 
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';  
import axios, { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react'; 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import { Avatar, Button } from '@mui/material';
import { apiRequest } from '../../../utils/apiRequest';

export interface IStanding {
    name: string
}

export default function DashboardContent(args: any) { 
  const [standings, setStandings] = React.useState([] as IStanding[]);
  const [bonusStandings, setBonusStandings] = React.useState([] as any[]);
  const [user, setUser] = React.useState(args.user as any) 
  const [open, setOpen] = React.useState(true);
  const [leagues, setLeagues] = React.useState(args.user.leagues as any[]) 
  const [league, setLeague] = React.useState({} as any) 
  const [isBonusHidden, setIsBonusHidden] = React.useState(false)

    useEffect(()=>{ 
        const getDashboardSmack = async() => {
          await getStandings(0); 
        }
        getDashboardSmack();

        if(leagues.length){
          const selectedLeague = leagues.filter((league) => league.groupId === user.groupId)
          if(selectedLeague.length){
            setLeague(selectedLeague[0]) 
          } 
          if(selectedLeague[0].sportTypeId % 2 === 1){
            setIsBonusHidden(true)
          }
        }
 

        setUser(args.user)
    }, [args]) // <-- empty dependency array
 

  const getStandings = async (index: number) => { 
    const result = await apiRequest.sendRequest(`api/v1/dashboard/standings`, 'post', { index })   
    const { standings, bonusStandings } = result;
    setBonusStandings(bonusStandings)
    setStandings(standings) 
  } 

  const getEmbargoText = () => {
    const league = user.leagues.filter((league:any) => league.groupId === user.groupId);
    return (
      <>Standings hidden until official start of the tournament on {new Date(league[0].poolStart).toLocaleDateString()} {new Date(league[0].poolStart).toLocaleTimeString()}</>
    )
  }
   
  const checkEmbargo = () => {
    if(user.groupId > 0 && user.leagues.length){ 
      const league = user.leagues.filter((league:any) => league.groupId === user.groupId);
      return new Date(league[0].poolStart).getTime() > new Date().getTime();
    }
    return true
  }

  const numberWithCommas = (num: number) => { 
    return  num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const renderBonusRows = (row: any) => {
  if(!isBonusHidden) { 
    return <>
    <TableCell  scope="row">
    ${numberWithCommas(row.Buyin80pct)}
    </TableCell>
    <TableCell  scope="row">
    ${numberWithCommas(row.Payout80pct)}
    </TableCell>
    </>
  }
}

const renderBonusHeaders = () => {
  if(!isBonusHidden) { 
    return <>
    <TableCell>80%<br />Buy in</TableCell>
            <TableCell>80%<br />Pay out</TableCell>
    </>
  }
}
  return (
   
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
            { user.groupId === 0 && 
                <><div style={{ padding: 20 }}>No group associated, join a <a style={{paddingLeft: 5}} href='/leagues'>league</a></div></>
              } 
               { user.groupId > 0 && checkEmbargo() &&
               <div>
           { getEmbargoText() }
           </div>
}
               { user.groupId > 0 && !checkEmbargo() &&
              <> 
              <div>
               <Button 
            variant="outlined" 
            onClick={async (event) => {
              await getStandings(0); 
        
            }} 
          >
           Win %
          </Button>
          <Button 
            variant="outlined" 
            onClick={async (event) => {
              await getStandings(1); 
        
            }} 
          >
           Plus Minus
          </Button>
          <Button
            
            variant="outlined" 
            onClick={async (event) => {
              await getStandings(2); 
        
            }} 
          >
            # of Wins
          </Button></div> 
              <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell>User</TableCell>
            <TableCell>W</TableCell>
            <TableCell>L</TableCell>
            <TableCell>P</TableCell>
            <TableCell>Win %</TableCell>
            <TableCell>Buy in</TableCell>
            {renderBonusHeaders()}
            <TableCell>100%<br />Pay out</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {standings.map((row: any) => (
            <TableRow
              key={row.Rank}
            >
              <TableCell component="th" scope="row">
                {row.Rank}
              </TableCell>
              <TableCell  scope="row">
              <Avatar alt={ row.Capper } src={ row.Avatar} /> {row.Capper}
              </TableCell>
              <TableCell  scope="row">
              {row.Wins} 
              </TableCell>
              <TableCell  scope="row">
               {row.Losses}   
              </TableCell>
              <TableCell  scope="row">
             {row.Pending}  
              </TableCell>
              <TableCell  scope="row">
              {(row.Wins + row.Losses) > 0 ? `${(row.Wins / (row.Wins + row.Losses) * 100).toFixed(0)} %` : '0 %' } 
              </TableCell>
              <TableCell  scope="row">
              ${numberWithCommas(row.Buyin)}
              </TableCell>
              {renderBonusRows(row)}
              <TableCell  scope="row">
              ${numberWithCommas(row.Payout100pct)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
     
      { !isBonusHidden && 
    <><h2>Bonus Table</h2><TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Rank</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>W | L | P</TableCell>
                    <TableCell>Win %</TableCell>
                    <TableCell>Buy in</TableCell>
                    <TableCell>20%<br />Buy in</TableCell>
                    <TableCell>20%<br />Pay out</TableCell>
                    <TableCell>100%<br />Pay out</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bonusStandings.map((row: any) => (
                    <TableRow
                      key={row.Rank}
                    >
                      <TableCell component="th" scope="row">
                        {row.Rank}
                      </TableCell>
                      <TableCell scope="row">
                        <Avatar alt={row.Capper} src={row.Avatar} /> {row.Capper}
                      </TableCell>
                      <TableCell scope="row">
                        {row.BonusWins} W | {row.BonusLosses} L | {row.BonusPending} P
                      </TableCell>
                      <TableCell scope="row">
                        {row.winPer}
                      </TableCell>
                      <TableCell scope="row">
                        {row.Buyin}
                      </TableCell>
                      <TableCell scope="row">
                        {row.Buyin20pct}
                      </TableCell>
                      <TableCell scope="row">
                        {row.Payout20pct}
                      </TableCell>
                      <TableCell scope="row">
                        {row.Payout100pct}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer></>
}
    </> }
            </Grid> 
          </Container>
  );
}


