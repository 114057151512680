import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'; 
import axios, { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { ISmack } from '../interfaces'; 
import SmackList from '../Home/smack.list';
import SmackSend from '../Home/smack.send';
import { apiRequest } from '../../../utils/apiRequest';


export default  function DashboardContent(args: any) { 
 
  const [user, setUser] = React.useState(args.user as any) 
  const [smack, setSmack] = React.useState([] as ISmack[]);

    useEffect(()=>{ 
        const getDashboardSmack = async() => await getSmack();
        getDashboardSmack();
 

        setUser(args.user)
    }, [args]) // <-- empty dependency array
 

  const getSmack = async () => { 
    const result = await apiRequest.sendRequest('api/v1/dashboard/smack', 'post')   
    setSmack(result)
  }

  

  return (
   
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
           {/* Smack Talk */}
           <Grid item xs={12} md={12} lg={12}>
           { user.groupId === 0 && 
                <>No group associated, join a <a style={{paddingLeft: 5}} href='/leagues'>league</a></>
              }
              { user.groupId > 0 && 
              <>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}> 
                <SmackList smack={smack}  />
                <SmackSend /> 
                </Paper>
              </>
}
              </Grid>
             
            </Grid> 
          </Container>
  );
}

 
