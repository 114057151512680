import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Toolbar, IconButton, Typography, Badge, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers'; 
import GroupsIcon from '@mui/icons-material/Groups';
import { authCheck } from '../../../utils/authCheck'
import ChatIcon from '@mui/icons-material/Chat';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

let appOpen = true;

const isBasketball = new Date().getMonth() >= 2 && new Date().getMonth() < 6

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(appOpen &&  {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!appOpen && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();


let init = false;
export const renderAppBar = (func: any) => {
     
    const league = func.leagues && func.leagues.length && func.user && func.user.groupId > 0 && func.leagues.filter((league: any) => league.groupId === func.user.groupId).length ? func.leagues.filter((league: any) => league.groupId === func.user.groupId)[0].name : 'Please select a league'
 
    const mobile = !func.isMobile;
    if(!mobile && !init){
        init = true;
        appOpen = !func.isMobile;
    }

    const user = func.user ? func.user : { }
    
    const click = (e: any) => { 
        appOpen = !appOpen;
        func.setOpen(appOpen)
    }
    return <AppBar position="absolute" open={appOpen}>
    <Toolbar
      sx={{
        pr: '24px', // keep right padding when drawer closed
      }}
    >
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={click}
        sx={{
          marginRight: '36px',
           
        }}
      >
        <MenuIcon />
      </IconButton>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
      >
        P60 - { user?.userName }
      </Typography>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
      >
        { league }
      </Typography>
     
    </Toolbar>
  </AppBar>
  }

 export const renderDrawer = (func: any) => {
 
 
    let user = localStorage.getItem('user') as any
    if(user){
        user = JSON.parse(user)
    }

    const click = (e: any) => { 
        appOpen = !appOpen;
        func.setOpen(appOpen)
    }

    const navigate = (destination: string) => {
        window.location.href = `/${destination}`
    }

    return <Drawer variant="permanent" open={appOpen}>
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
      }}
    >
      <IconButton onClick={click}>
        <ChevronLeftIcon />
      </IconButton>
    </Toolbar>
    <Divider />
    <List component="nav"> 
      <Divider sx={{ my: 1 }} /> 
      <React.Fragment>
      <ListItemButton onClick={() => navigate('leagues')}>
      <ListItemIcon>
        <GroupsIcon />
      </ListItemIcon>
      <ListItemText primary="Leagues" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('dashboard')}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('smack')}>
      <ListItemIcon>
        <ChatIcon />
      </ListItemIcon>
      <ListItemText primary="Smack Talk" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('standings')}>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Standings" />
    </ListItemButton>

   { !isBasketball &&  
    <ListItemButton onClick={() => navigate('odds?sport=0')}>
      <ListItemIcon>
        <SportsFootballIcon />
      </ListItemIcon>
      <ListItemText primary="Ncaa Football" />
    </ListItemButton>
 }
    { !isBasketball &&  
    <ListItemButton onClick={() => navigate('odds?sport=2')}>
      <ListItemIcon>
        <SportsFootballIcon />
      </ListItemIcon>
      <ListItemText primary="NFL Football" />
    </ListItemButton>
 }

{ isBasketball && 
    <ListItemButton onClick={() => navigate('odds?sport=1')}>
      <ListItemIcon>
        <SportsBasketballIcon />
      </ListItemIcon>
      <ListItemText primary="Ncaa Basketball" />
    </ListItemButton>
 }

    <ListItemButton onClick={() => navigate('my-picks')}>
      <ListItemIcon>
        <AppShortcutIcon />
      </ListItemIcon>
      <ListItemText primary="My Picks" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('picks')}>
      <ListItemIcon>
        <WorkspacesIcon />
      </ListItemIcon>
      <ListItemText primary="Everyone's Picks" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('rules')}>
      <ListItemIcon>
        <GavelIcon />
      </ListItemIcon>
      <ListItemText primary="Rules" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('profile')}>
      <ListItemIcon>
        <AccountCircleIcon />
      </ListItemIcon>
      <ListItemText primary="My Profile" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('login')}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItemButton>

{ authCheck.isAdmin(user.userName ?? '') && 
<div>
    <Divider sx={{ my: 1 }} /> 
    <ListItemButton onClick={() => navigate('admin/scoring')}>
      <ListItemIcon>
        <SportsScoreIcon />
      </ListItemIcon>
      <ListItemText primary="Manual Scoring" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('admin/bot')}>
      <ListItemIcon>
        <SmartToyIcon />
      </ListItemIcon>
      <ListItemText primary="Score bot" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('admin/users')}>
      <ListItemIcon>
        <ManageAccountsIcon />
      </ListItemIcon>
      <ListItemText primary="User Mgr" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('admin/leagues')}>
      <ListItemIcon>
        <ManageHistoryIcon />
      </ListItemIcon>
      <ListItemText primary="League Mgr" />
    </ListItemButton>
    </div>
 }
  </React.Fragment>
    </List>
  </Drawer>
  }
 

export { mdTheme, AppBar, Drawer }
 
